// 阴影
.mdui-shadow(@depth) {
  @shadow: 'shadow-depth-@{depth}';
  box-shadow: @@shadow;
}

// hover 时添加阴影
.mdui-hoverable(@depth: 8) {
  will-change: box-shadow;
  transition: box-shadow .25s @animation-curve-default;

  &:hover,
  &:focus {
    .mdui-shadow(@depth);
  }
}