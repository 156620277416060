/**
 * =============================================================================
 * ************   公共样式   ************
 * =============================================================================
 */

body {
  font-family: @font-family-base;
  font-size: @screen-xs-font-size;
  background-color: @body-background-color;
  color: @body-text-color;
  @media (min-width: @screen-sm-min) {
    font-size: @screen-sm-font-size;
  }
  @media (min-width: @screen-md-min) {
    font-size: @screen-md-font-size;
  }

  // 滚动条样式
  * {
    .mdui-scrollbar-beautify(light);
  }

  // 夜间模式滚动条样式
  &.mdui-theme-layout-dark {
    * {
      .mdui-scrollbar-beautify(dark);
    }
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* 锁定屏幕 */
body.mdui-locked {
  overflow: hidden;
}

/* 遮罩层 */
.mdui-overlay {
  position: fixed;
  z-index: @z-index-overlay;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  backface-visibility: hidden;
  background: rgba(0, 0, 0, .4);
  will-change: opacity;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: .3s;
}
/* 显示遮罩层 */
.mdui-overlay-show {
  opacity: 1;
  visibility: visible;
}

/* 取消 transition 过渡效果 */
.mdui-no-transition {
  transition-property: none !important;
}


/**
 * =============================================================================
 * ************   Global dark   ************
 * =============================================================================
 */
.mdui-theme-layout-dark when (@globalLayoutDark = true) {
  background-color: @layout-dark-color-3;
  color: #fff;
}