/**
 * =============================================================================
 * ************   Menu 菜单   ************
 * =============================================================================
 */

// 普通菜单宽度基数
@menu-simple-x: 56px;

// 级联菜单宽度基数
@menu-cascade-x: 64px;

/* 菜单 */
.mdui-menu {
  box-sizing: border-box;
  padding: 8px 0;
  margin: 0;
  font-size: 16px;
  .mdui-shadow(8);
  border-radius: 2px;
  width: @menu-simple-x * 3;
  background-color: #fff;
  color: @color-black-text;
  list-style: none;
  display: block;
  z-index: 99999;
  opacity: 0;
  will-change: transform, opacity, visibility;
  transition-property: transform, opacity, visibility;
  transition-duration: .3s;
  transition-timing-function: @animation-curve-linear-out-slow-in;

  visibility: hidden;
  transform: scale(0);
  position: fixed;

  // 简单菜单高度超出窗口高度时，使菜单边框和窗口保持一定距离，菜单内出现滚动条
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  // 菜单中的分隔线，默认上下有 8px 的外边距
  .mdui-divider {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

/* 打开状态的菜单 */
.mdui-menu-open {
  visibility: visible;
  transform: scale(1);
  opacity: 1;
}

/* 关闭中的菜单 */
.mdui-menu-closing {
  visibility: visible;
  transform: scale(1);
  opacity: 0;
}

/* 菜单项 */
.mdui-menu-item {
  position: relative;

  &>a {
    position: relative;
    display: block;
    color: inherit;
    text-decoration: none;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    user-select: none;
    .mdui-text-truncate();

    &:hover {
      background-color: @color-grey-200;
    }
  }

  // 子菜单默认隐藏，阴影更深
  &>.mdui-menu {
    position: absolute;
    .mdui-shadow(9);
  }

  // 禁用的菜单条目
  &[disabled] {
    &>a {
      cursor: default;
      color: @color-black-disabled !important;

      &:hover {
        background-color: inherit !important;
      }

      .mdui-icon {
        color: @color-black-icon-disabled;
      }
    }
  }
}

/* 激活状态的菜单项 */
.mdui-menu-item-active {
  background-color: @color-grey-200;
}

/* 菜单中的图标 */
.mdui-menu-item-icon {
  color: @color-black-icon;
  width: 40px;
  padding-right: 16px;
  box-sizing: border-box;
  display: inline-block;
}

/* 菜单中的辅助文本或图标 */
.mdui-menu-item-helper {
  float: right;
}

/* 有子菜单的条目的图标 */
.mdui-menu-item-more {
  float: right;
  width: 24px;
  height: 24px;
  margin: 4px 0;
  background-image: data-uri('image/svg+xml', './menu/svg/arrow_right_dark.svg');
}


/* 级联菜单 */
.mdui-menu-cascade {
  padding: 16px 0;
  font-size: 15px;
  width: @menu-cascade-x * 5;

  // 级联菜单内部不出现滚动条
  overflow-y: visible;

  &>.mdui-menu-item {
    &>a {
      height: 32px;
      line-height: 32px;
      padding: 0 24px;
    }
  }
}


/**
 * =============================================================================
 * ************   Menu dark   ************
 * =============================================================================
 */
.mdui-theme-layout-dark when (@globalLayoutDark = true) {
  .mdui-menu {
    background-color: @layout-dark-color-4;
    color: #fff;
  }

  .mdui-menu-item {
    &>a {
      &:hover {
        background-color: @color-grey-700;
      }
    }

    &[disabled] {
      &>a {
        color: @color-white-disabled !important;

        .mdui-icon {
          color: @color-white-icon-disabled;
        }
      }
    }
  }

  .mdui-menu-item-active {
    background-color: @color-grey-700;
  }

  .mdui-menu-item-icon {
    color: @color-white-icon;
  }

  .mdui-menu-item-more {
    background-image: data-uri('image/svg+xml', './menu/svg/arrow_right_light.svg');
  }
}
