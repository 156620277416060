/**
 * =============================================================================
 * ************   Table 表格   ************
 * =============================================================================
 */

.mdui-table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid @color-black-divider;
  border-bottom: none;
  .mdui-shadow(2);

  tbody {
    tr {
      position: relative;
      transition: background-color 0.28s @animation-curve-default;
    }
  }

  th, td {
    position: relative;
    vertical-align: middle;
    text-align: left;
    padding: 12px 28px;
    box-sizing: border-box;
    .mdui-divider-bottom-dark();

    &:last-child {
      padding-right: 24px;
    }
    &:first-child {
      padding-left: 24px;
      padding-right: 0
    }
    &:nth-child(2) {
      padding-left: 24px;
    }
  }

  th {
    font-size: 13px;
    color: @color-black-secondary;
    font-weight: 700;
    .mdui-text-truncate();
    line-height: 32px;
  }

  td {
    font-size: 14px;
    color: @color-black-text;
    line-height: 24px;
  }
}

/* 每一行前面的复选框 */
.mdui-table-cell-checkbox {
  padding-left: 24px !important;
  padding-top: 0 !important;
  padding-bottom: 0!important;

  .mdui-checkbox {
    margin-top: 7px;
  }

  & + td,
  & + th {
    padding-left: 6px !important;
  }
}
th.mdui-table-cell-checkbox {
  .mdui-checkbox {
    margin-top: 11px;
  }
}

/* 鼠标悬浮时行背景加深 */
.mdui-table-hoverable {
  tbody tr {
    &:hover {
      background-color: @color-grey-200;
    }
  }
}

/* 表格放到该元素内，使表格产生滚动条时只在该元素内滚动 */
.mdui-table-fluid {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid @color-black-divider;
  border-bottom: none;
  .mdui-shadow(2);

  .mdui-table {
    .mdui-shadow(0);
    border: none;
    margin: 0;
  }
}

/* 数字列，右对齐 */
.mdui-table-col-numeric {
  text-align: right !important;
}

/* 行处于选中状态 */
.mdui-table-row-selected {
  background-color: @color-grey-100;
}


/**
 * =============================================================================
 * ************   Table dark   ************
 * =============================================================================
 */
.mdui-theme-layout-dark when (@globalLayoutDark = true) {
  .mdui-table {
    background-color: @layout-dark-color-3;
    border: 1px solid @color-white-divider;
    border-bottom: none;

    th, td {
      .mdui-divider-bottom-light();
    }

    th {
      color: @color-white-secondary;
    }

    td {
      color: @color-white-text;
    }
  }

  .mdui-table-hoverable {
    tbody tr {
      &:hover {
        background-color: @color-grey-700;
      }
    }
  }

  .mdui-table-fluid {
    border: 1px solid @color-white-divider;
    border-bottom: none;

    .mdui-table {
      .mdui-shadow(0);
      border: none;
    }
  }

  .mdui-table-row-selected {
    background-color: @color-grey-800;
  }
}
