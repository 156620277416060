/**
 * =============================================================================
 * ************   Expansion panel 可扩展面板   ************
 * =============================================================================
 */

/* 可扩展面板 */
.mdui-panel {
  box-sizing: border-box;
  width: 100%;
}

/* 面板项 */
.mdui-panel-item {
  background-color: #fff;
  color: @color-black-text;
  .mdui-divider-bottom-dark();
  will-change: margin;
  transition: margin .3s @animation-curve-default;
  .mdui-shadow(2);

  &:last-child {
    border-bottom: none;
  }
}

/* 面板头部 */
.mdui-panel-item-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  font-size: 15px;
  cursor: pointer;
  will-change: height, background-color;
  transition: all .3s @animation-curve-default;
  .mdui-text-truncate();

  &:active {
    background-color: @color-grey-200;
  }
}

/* 面板项标题 */
.mdui-panel-item-title {
  box-sizing: border-box;
  width: 36%;
  min-width: 36%;
  font-weight: 500;
  padding-right: 16px;
  .mdui-text-truncate();
}

/* 面板项内容 */
.mdui-panel-item-summary {
  flex-grow: 1;
  box-sizing: border-box;
  padding-right: 16px;
  color: @color-black-secondary;
  .mdui-text-truncate();
}

/* 展开收起的图标 */
.mdui-panel-item-arrow {
  position: absolute;
  right: 24px;
  top: 12px;
  color: @color-black-icon;
  user-select: none;
  will-change: transform, top;
  transform: rotate(0);
  transition: all .3s @animation-curve-default;
}

.mdui-panel-item-summary + .mdui-panel-item-arrow {
  position: relative;
  right: 0;
  top: 0 !important;
}

/* 面板项内容 */
.mdui-panel-item-body {
  padding: 0 24px;
  height: 0;
  overflow: hidden;
  .mdui-clearfix();
  will-change: height;
  transition: height .3s @animation-curve-default;

  &:after {
    height: 16px;
  }
}

/* 面板项操作栏 */
.mdui-panel-item-actions {
  display: block;
  width: 100%;
  text-align: right;
  margin: 16px -24px 0 -24px;
  padding: 16px 24px 0 24px;
  .mdui-divider-top-dark();

  .mdui-btn {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
}

/* 使打开项上下没有边距 */
.mdui-panel-gapless {
  .mdui-shadow(2);

  .mdui-panel-item {
    .mdui-shadow(0);
  }

  .mdui-panel-item-open {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* 弹出面板 */
.mdui-panel-popout {
  .mdui-panel-item-open {
    margin-left: -16px;
    margin-right: -16px;
  }
}

/* 打开状态的面板项 */
.mdui-panel-item-open {
  height: auto;
  margin-top: 16px;
  margin-bottom: 16px;

  // 打开状态的面板头部更高
  &>.mdui-panel-item-header {
    height: 64px;

    .mdui-panel-item-arrow {
      transform: rotate(180deg);
      top: 20px;
    }
  }

  &>.mdui-panel-item-body {
    height: auto;
  }
}


/**
 * =============================================================================
 * ************   Expansion panel dark   ************
 * =============================================================================
 */
.mdui-theme-layout-dark when (@globalLayoutDark = true) {
  .mdui-panel-item {
    background-color: @layout-dark-color-3;
    color: #fff;
    .mdui-divider-bottom-light();

    &:last-child {
      border-bottom: none;
    }
  }
  .mdui-panel-item-header {
    &:active {
      background-color: @color-grey-800;
    }
  }
  .mdui-panel-item-summary {
    color: @color-white-secondary;
  }
  .mdui-panel-item-arrow {
    color: @color-white-icon;
  }
  .mdui-panel-item-actions {
    .mdui-divider-top-light();
  }
}