/**
 * =============================================================================
 * ************   Typography 排版   ************
 * =============================================================================
 */

/* .mdui-typo-display-4 */
.mdui-typo-display-4,
.mdui-typo-display-4-opacity {
  .mdui-typo-display-4();
}
.mdui-typo-display-4-opacity {
  ._mdui-typo-display-4-opacity();
}

/* .mdui-typo-display-3 */
.mdui-typo-display-3,
.mdui-typo-display-3-opacity {
  .mdui-typo-display-3();
}
.mdui-typo-display-3-opacity {
  ._mdui-typo-display-3-opacity();
}

/* .mdui-typo-display-2 */
.mdui-typo-display-2,
.mdui-typo-display-2-opacity {
  .mdui-typo-display-2();
}
.mdui-typo-display-2-opacity {
  ._mdui-typo-display-2-opacity();
}

/* .mdui-typo-display-1 */
.mdui-typo-display-1,
.mdui-typo-display-1-opacity {
  .mdui-typo-display-1();
}
.mdui-typo-display-1-opacity {
  ._mdui-typo-display-1-opacity();
}

/* .mdui-typo-headline */
.mdui-typo-headline,
.mdui-typo-headline-opacity {
  .mdui-typo-headline();
}
.mdui-typo-headline-opacity {
  ._mdui-typo-headline-opacity();
}

/* .mdui-typo-title */
.mdui-typo-title,
.mdui-typo-title-opacity {
  .mdui-typo-title();
}
.mdui-typo-title-opacity {
  ._mdui-typo-title-opacity();
}

.mdui-typo-subheading,
.mdui-typo-subheading-opacity {
  .mdui-typo-subheading();
}
.mdui-typo-subheading-opacity {
  ._mdui-typo-subheading-opacity();
}

.mdui-typo-body-2,
.mdui-typo-body-2-opacity {
  .mdui-typo-body-2();
}
.mdui-typo-body-2-opacity {
  ._mdui-typo-body-2-opacity();
}

.mdui-typo-body-1,
.mdui-typo-body-1-opacity {
  .mdui-typo-body-1();
}
.mdui-typo-body-1-opacity {
  ._mdui-typo-body-1-opacity();
}

.mdui-typo-caption,
.mdui-typo-caption-opacity {
  .mdui-typo-caption();
}
.mdui-typo-caption-opacity {
  ._mdui-typo-caption-opacity();
}

.mdui-typo {
  line-height: 1.8;
  word-wrap: break-word;

  address, caption, cite, code, dfn, th {
    font-style: normal;
    font-weight: 400;
  }

  caption, th {
    text-align: left;
  }

  q:before, q:after {
    content: '';
  }

  pre, code, kbd, samp, pre tt {
    font-family: Consolas, Courier, 'Courier New', monospace;
  }

  figcaption {
    font-size: 80%;
    color: @color-black-secondary;
  }

  [draggable] {
    cursor: move;
  }

  p, pre, ul, ol, dl, form, hr, figure, table, .mdui-table, .mdui-table-fluid {
    margin: 0 0 1.2em 0;
  }

  /* a */
  a {
    .mdui-text-color(@color-accent-default-name, a200);
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    outline: none;

    &:before {
      position: absolute;
      top: auto;
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: @color-default-a200;
      content: ' ';
      transition: all 0.2s;
      backface-visibility: hidden;
      transform: scaleX(0);
    }

    &:hover:before,
    &:focus:before {
      transform: scaleX(1);
    }
  }

  /* small */
  small {
    font-size: 80%;
  }

  /* blockquote */
  blockquote {
    font-weight: 400;
    border-left: 4px solid @color-black-divider;
    padding-left: 1em;
    margin: 1em 3em 1em 2em;
    @media only screen and ( max-width: @screen-xs-max ) {
      margin: 1em 0;
    }

    footer {
      color: @color-black-secondary;
      font-size: 86%;
    }
  }

  /* mark */
  mark {
    background: #fffdd1;
    border-bottom: 1px solid #ffedce;
    padding: 2px;
    margin: 0 5px;
  }

  /* h1 - h6 */
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    color: inherit;
    font-family: inherit;
    margin-top: 1.2em;
    margin-bottom: 0.6em;
    line-height: 1.35;

    small {
      font-size: 65%;
      line-height: 1;
      font-weight: 400;
      color: @color-black-secondary;
    }
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.8em;
  }
  h3 {
    font-size: 1.6em;
  }
  h4 {
    font-size: 1.4em;
  }
  h5 {
    font-size: 1.2em;
  }
  h6 {
    font-size: 1.1em;
  }

  /* code */
  code {
    padding: 2px 6px;
    color: #c7254e;
    background-color: #f7f7f9;
    border-radius: 2px;
  }

  /* pre code */
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0;
    line-height: 1.7;
  }

  /* abbr */
  abbr[title] {
    cursor: help;
    text-decoration: none;
    border-bottom: 1px dotted;
  }

  /* ins */
  ins {
    text-decoration: none;
    border-bottom: 1px solid ;
  }

  /* u */
  u {
    text-decoration: none;
    border-bottom: 1px solid;
  }

  /* del */
  del {
    text-decoration: line-through;
  }

  /* hr */
  hr {
    border: none;
    border-bottom: 1px solid @color-black-divider;
    margin-bottom: 0.8em;
    height: 10px;
  }

  /* pre */
  pre {
    border-radius: 2px;
    border: 1px solid @color-black-divider;
    padding: 12px 16px;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }

  /* kbd */
  kbd {
    padding: 2px 6px;
    font-size: 90%;
    color: #fff;
    background-color: #333;
    border-radius: 2px;
  }

  /* ul / ol */
  ul {
    padding-left: 2em;
    list-style: disc;
  }
  ol {
    list-style: decimal;
    padding-left: 2em;
  }
  li ul,
  li ol {
    margin: 0.8em 0;
  }
  li ul {
    list-style: circle;
  }

  /* img */
  img {
    max-width: 100%;
  }
}


/**
 * =============================================================================
 * ************   Typo 强调色   ************
 * =============================================================================
 */
& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));
    @colorName: extract(@globalAccentColors, @counter-color);

    .mdui-theme-accent-@{colorName} {
      .mdui-typo {
        a {
          .mdui-text-color(@colorName, a200);

          &:before {
            .mdui-background-color(@colorName, a200);
          }
        }
      }
    }

  }
  .loop-accent-theme(length(@globalAccentColors));
}


/**
 * =============================================================================
 * ************   Typo dark   ************
 * =============================================================================
 */
.mdui-theme-layout-dark when (@globalLayoutDark = true) {
  .mdui-typo {

    blockquote {
      border-left-color: @color-white-divider;

      footer {
        color: @color-white-secondary;
      }
    }

    figcaption {
      color: @color-white-secondary;
    }

    mark {
      background: #aaa;
      border-bottom-color: #bbb;
    }

    h1, h2, h3, h4, h5, h6 {
      small {
        color: @color-white-secondary;
      }
    }

    code {
      background-color: #424242;
      color: @color-red-100;
    }

    pre {
      background: #424242;
      border-color: @color-white-divider;
    }

    kbd {
      background: #424242;
    }

    hr {
      border-color: @color-white-divider;
    }

  }
}
