/**
 * =============================================================================
 * ************   Grid List 网格列表   ************
 * =============================================================================
 */

/* 调整网格边距 */
.mdui-grid-list {
  margin: 0 -@grid-list-gutter / 2;

  .mdui-col,
  [class*="mdui-col-xs-"],
  [class*="mdui-col-sm-"],
  [class*="mdui-col-md-"],
  [class*="mdui-col-lg-"],
  [class*="mdui-col-xl-"] {
    padding-left: @grid-list-gutter / 2;
    padding-right: @grid-list-gutter / 2;
  }
}

/* 单元格 */
.mdui-grid-tile {
  box-sizing: border-box;
  margin-bottom: @grid-list-gutter;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    width: 100%;
  }
}

/* 操作栏 */
.mdui-grid-tile-actions {
  display: flex;
  align-items: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 48px;
  max-height: 68px;
  padding: 16px;
  box-sizing: border-box;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);

  .mdui-icon {
    color: #fff;
  }
}

/* 操作栏内的文本 */
.mdui-grid-tile-text {
  overflow: hidden;
  flex: 1;
}

/* 标题 */
.mdui-grid-tile-title {
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  .mdui-text-truncate();

  .mdui-icon {
    margin-right: 8px;
  }
}

/* 副标题 */
.mdui-grid-tile-subtitle {
  margin-top: 4px;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  .mdui-text-truncate();

  .mdui-icon {
    font-size: 18px;
    margin-right: 8px;
  }
}

/* 操作栏内的按钮 */
.mdui-grid-tile-buttons {
  white-space: nowrap;
  margin: -8px;
  flex: none;

  .mdui-btn {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.mdui-grid-tile-text + .mdui-grid-tile-buttons {
  margin-left: 8px;
}
.mdui-grid-tile-buttons + .mdui-grid-tile-text {
  margin-left: 16px;
}

/* 操作栏位于顶部 */
.mdui-grid-tile-actions-top {
  bottom: auto;
  top: 0;
}

/* 操作栏背景透明 */
.mdui-grid-tile-actions-transparent {
  background: transparent;
}

/* 操作栏背景渐变 */
.mdui-grid-tile-actions-gradient {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0));

  &.mdui-grid-tile-actions-top {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0));
  }
}